// @ts-check
/**
 * @typedef {{ username: string, email?: string, avatar: string, department: string, roles?: string[] }} UserResult
 * @typedef {{ password: string, oldPassword: string } & UserResult} UserData
 */

import { API_ENDPOINT } from '.';

class UserError extends Error {}

/**
 * Calls the API to receive the user that is currently signed in
 * @return {Promise<[UserResult, UserError]>}
 */
export const getUser = () => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/user`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'GET'
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new UserError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          default:
            return resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(user => {
        // TODO: make sure that the result is in fact a UserResult object
        resolve([user, undefined]);
      })
      .catch(e => {
        console.error(e); // we don't wanna swallow the error
        resolve([
          undefined,
          new UserError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};

/**
 * Updates a user profile by calling the API
 * @param {UserData} user
 * @return {Promise<[any, UserError]>}
 */
export const patchUser = ({ username, password, oldPassword, department, email, avatar }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/user`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'PATCH',
      body: JSON.stringify({
        username,
        password,
        oldPassword,
        department,
        email,
        avatar
      })
    })
      .then(response => {
        switch (response.status) {
          case 200:
            return response.json();
          case 401:
            return resolve([
              undefined,
              new UserError(
                'Sie sind nicht autorisiert diese Seite zu bearbeiten. Bitte loggen Sie sich ein.'
              )
            ]);
          case 409:
            return resolve([undefined, new UserError('Ihr altes Passwort ist nicht korrekt.')]);
          default:
            return resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
        }
      })
      .then(user => {
        resolve([user, undefined]);
      })
      .catch(e => {
        console.error(e);
        resolve([undefined, new UserError('Es ist etwas schief gelaufen.')]);
      });
  });
};

/**
 * Attempts to log a user in
 * @param { {username: string, password: string} } LoginData
 * @return {Promise<[any, UserError]>}
 */
export const login = ({ username, password }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/login`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        username: username,
        password: password
      })
    })
      .then(response => {
        if (response.status !== 200) {
          return resolve([undefined, new UserError('Ungültiger Benutzername oder Passwort')]);
        } else {
          return response.json();
        }
      })
      .then(user => {
        return resolve([user, undefined]);
      })
      .catch(e => {
        console.error(e);
        return resolve([
          undefined,
          new UserError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};
