import { API_ENDPOINT } from '.';

class FeedbackError extends Error {}

/**
 * Sends user feedback to the API
 * @param {{subject: string, text: string, email: string, name: string}} param0
 * @return {Promise<[any, FeedbackError]>}
 */
export const sendFeedback = ({ subject, text, email, name }) => {
  return new Promise(resolve => {
    fetch(`${API_ENDPOINT}/api/feedback`, {
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify({
        subject,
        text,
        email,
        name
      })
    })
      .then(response => {
        if (response.status === 200) {
          return resolve([true, undefined]);
        } else {
          return resolve([undefined, new FeedbackError('Ungültiger Benutzername oder Passwort')]);
        }
      })
      .catch(e => {
        console.error(e);
        return resolve([
          undefined,
          new FeedbackError(
            'Es ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Internetverbindung oder probieren Sie es später noch einmal.'
          )
        ]);
      });
  });
};
