import React from 'react';
import { getUser } from '../api/user';

const defaultState = {
  loading: true,
  loggedIn: false,
  error: null,
  // pure userdata
  username: null,
  department: null,
  avatar: null,
  roles: [],
  email: null
};
defaultState.setUser = () => {};

export const UserContext = React.createContext(defaultState);

export class UserProvider extends React.Component {
  state = defaultState;

  componentDidMount() {
    getUser()
      .then(([user, err]) => {
        if (err) {
          return this.setState({ loggedIn: false, error: err.message, loading: false });
        }

        this.setState({ loggedIn: true, error: null, loading: false, ...user });
      })
      .catch(e => {
        console.error(e);
        this.setState({ loggedIn: false, error: 'Something unexpected happened!', loading: false });
      });
  }

  login = user => {
    this.setState({ loggedIn: true, error: null, loading: false, ...user });
  };

  logout = () => {
    const { setUser, ...rest } = defaultState;

    this.setState({ loading: false, rest });
  };

  render() {
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          ...this.state,
          login: this.login
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}
